import { Dispatch, ReactNode } from 'react';

import UserInterface from './user';
import CourseInterface from './courses';

export enum TypeAction {
  REQUESTING_DATA = 'REQUESTING_DATA',
  GET_PAYMENT = 'GET_PAYMENT',
}

// CoursesContext Interfaces
export type Action = {
  type: TypeAction.REQUESTING_DATA;
} | {
  type: TypeAction.GET_PAYMENT;
  payment: PaymentInterface
};

export interface PaymentsContextType {
  state: { payment: PaymentInterface };
  dispatch: Dispatch<Action>;
}

// Payment Interfaces
export enum PaymentDisplayStatuses {
  cancelled = 'Cancelado',
  pending = 'Pendiente',
  payed = 'Confirmado',
}

export enum PaymentStatuses {
  cancelled = 'cancelled',
  pending = 'pending',
  payed = 'payed',
}

export enum PaymentDisplayMethods {
  mercadopago = 'Mercadopago',
  mercadopagoSubscription = 'Mercadopago',
  paymentLink = 'Link de pago',
}

export enum PaymentDisplayDocumentTypes {
  dni = 'DNI',
  cuil = 'CUIL',
  cuit = 'CUIT',
  le = 'LE',
}

export enum PaymentMethod {
  Mercadopago = 'mercadopago',
  MercadopagoSubscription = 'mercadopagoSubscription',
  PaymentLink = 'paymentLink',
}

export interface CreatePaymentInterface {
  course: string,
  mpRequest?: object,
  method: PaymentMethod,
  conceptUser: string,
}

export interface UpdateInstallmentsInterface {
  installment: number | 'preinscription';
  reason: string;
  isPaid: boolean;
  paymentDate: Date;
}

interface Installment {
  installment: number | 'preinscription';
  amount: number;
  isPaid: boolean;
  paymentDate?: Date;
  mpRequest?: object;
  mpResponse?: object;
  reason?: string;
}
export default interface PaymentInterface {
  id: string;
  user: string;
  course: string;
  conceptUser: string;
  method: PaymentMethod;
  currency: 'ars';
  status: PaymentDisplayStatuses;
  installmentsData: Installment[];
}

export interface PopulatedPaymentInterface extends Omit<PaymentInterface, 'course' | 'user'> {
  _id?: string;
  course: CourseInterface;
  courseName?: string;
  user: UserInterface;
}
export interface QueryGetPaymentsInterface {
  limit?: number;
  page?: number;
  state?: string;
  search?: string;
}

export interface RowInterface {
  amount: string;
  courseName: string;
  document: string;
  editCollection?: ReactNode;
  email: string;
  fullName: string;
  id: string;
  method: string;
  seeDetails?: ReactNode;
  status: ReactNode;
}

export interface ResGetAllPaymentsInterface {
  payments: PopulatedPaymentInterface[];
  page: number;
  total: number;
}

export type DownloadPaymentProps = {
  courseName: string;
  installmentId: string;
  paymentId: string;
};
