import { memo, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {
  Close as CloseIcon,
} from '@mui/icons-material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import { useAuth } from '../../../contexts/auth/context';
import { usePayments } from '../../../contexts/payments/context';

import RenderHeader from './components/renderHeader';

import { ROLES } from '../../../helpers/roles';

import {
  buildColumn,
  buildRows,
  RowInterface,
} from './constants';

import {
  PaymentDisplayStatuses,
  PaymentStatuses,
  PopulatedPaymentInterface,
  UpdateInstallmentsInterface,
} from '../../../interfaces/payments';

import classes from './classes';

interface Props {
  onClickPaymentModify: (a: UpdateInstallmentsInterface[]) => void;
  onCloseModal: () => void;
  openModal: boolean;
  paymentData: PopulatedPaymentInterface;
}

const PaymentDetailsModal = ({
  onClickPaymentModify,
  onCloseModal,
  openModal,
  paymentData,
}: Props) => {
  const { downloadPayment } = usePayments();
  const { state } = useAuth();
  const { role } = state.auth;
  const isAdmin = role === ROLES.ADMIN;
  const [rows, setRows] = useState<RowInterface[]>(buildRows(paymentData));

  const downloadPaymentPdf = async (installmentId: string) => {
    await downloadPayment({
      courseName: paymentData.courseName,
      installmentId,
      paymentId: paymentData.id,
    });
  };

  const modifyRows = (rowIndex: string, rowKey: string, newRowValue: any): void => {
    const modifiedRows = rows.map((row) => {
      if (row.id === rowIndex) {
        return {
          ...row,
          [rowKey]: newRowValue,
        };
      }
      return row;
    });
    setRows(modifiedRows);
  };

  const columns: GridColDef[] = [
    buildColumn({ field: 'detail', headerName: 'Detalle', width: 120 }),
    buildColumn({ field: 'amount', headerName: 'Monto', width: 120 }),
    {
      field: 'status',
      headerName: 'Estado',
      hideSortIcons: true,
      renderCell: (cell) => (isAdmin ? (
        <FormControl variant="standard">
          <Select
            disabled={paymentData.status === PaymentDisplayStatuses.cancelled}
            id={`status-row-${cell.row.id}`}
            inputProps={{ sx: { fontSize: '12px', padding: 0 } }}
            onChange={(e) => modifyRows(cell.row.id, 'status', e.target.value)}
            value={cell.row.status}
          >
            <MenuItem value="payed" sx={{ fontSize: '12px' }}>Aprobado</MenuItem>
            <MenuItem value="pending" sx={{ fontSize: '12px' }}>Pendiente</MenuItem>
          </Select>
        </FormControl>
      ) : (
        <p>
          {PaymentDisplayStatuses[cell.row.status]}
        </p>
      )),
      renderHeader: () => RenderHeader('Estado'),
      sortable: false,
      width: 120,
    },
    {
      field: 'paymentDate',
      headerName: `Fecha de ${isAdmin ? 'cobro' : 'pago'}`,
      hideSortIcons: true,
      renderCell: (cell) => (isAdmin ? (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
          <DatePicker
            disabled={
              paymentData.status === PaymentDisplayStatuses.cancelled
              || cell.row.status === PaymentStatuses.pending
            }
            format="DD / MM / YYYY"
            onChange={(newValue) => modifyRows(cell.row.id, 'paymentDate', newValue)}
            slotProps={{ textField: { variant: 'standard' } }}
            value={
              cell.row.paymentDate
                && !(cell.row.status === PaymentStatuses.pending)
                ? dayjs(cell.row.paymentDate) : null
            }
            sx={{ fontSize: '12px' }}
          />
        </LocalizationProvider>
      ) : (
        <p>
          {cell?.row?.paymentDate ? dayjs(cell.row.paymentDate).format('DD / MM / YYYY') : 'Ninguna'}
        </p>
      )),
      renderHeader: () => RenderHeader(`Fecha de ${isAdmin ? 'cobro' : 'pago'}`),
      sortable: false,
      width: 186,
    },
  ];

  if (isAdmin) {
    columns.push({
      field: 'reason',
      headerName: 'Motivo',
      hideSortIcons: true,
      renderCell: (cell) => (
        <FormControl variant="standard" sx={{ mt: 2.5 }}>
          <TextField
            disabled={paymentData.status === PaymentDisplayStatuses.cancelled}
            id={`reason-row-${cell.row.id}`}
            inputProps={{ sx: { fontSize: '12px' } }}
            onChange={(e) => { modifyRows(cell.row.id, 'reason', e.target.value); }}
            onKeyDown={(e) => e.stopPropagation()}
            value={cell.row.reason}
            variant="standard"
          />
        </FormControl>
      ),
      renderHeader: () => RenderHeader('Motivo'),
      sortable: false,
      width: 186,
    });
  } else {
    columns.push({
      align: 'center',
      field: 'download',
      headerName: '',
      hideSortIcons: true,
      renderCell: (cell) => (
        <Button
          disabled={!cell.row.paymentDate}
          onClick={() => downloadPaymentPdf(cell.row.id)}
          sx={{ height: 'inherit', minWidth: 'inherit' }}
        >
          Descargar
        </Button>
      ),
      sortable: false,
      width: 186,
    });
  }

  const formatDataForUpdate = (): UpdateInstallmentsInterface[] => rows.map(({
    id,
    paymentDate,
    reason,
    status,
  }) => ({
    installment: id,
    isPaid: status === 'payed',
    paymentDate: paymentDate && status !== PaymentStatuses.pending ? new Date(paymentDate) : null,
    reason,
  }));

  return (
    <Modal onClose={onCloseModal} open={openModal}>
      <Box sx={classes.modalContainer}>
        <Box sx={classes.closeModalContainer}>
          <IconButton sx={classes.iconButton} onClick={onCloseModal}>
            <CloseIcon sx={classes.icon} />
          </IconButton>
        </Box>
        <Box sx={classes.bodyContainer}>
          {paymentData.status !== PaymentDisplayStatuses.cancelled && (
            <Typography sx={classes.editButton} variant="h2">
              {isAdmin ? 'Edición' : 'Cuotas'}
            </Typography>
          )}
          <DataGrid
            autoHeight
            columns={columns}
            disableColumnFilter
            disableColumnMenu
            disableRowSelectionOnClick
            hideFooter
            rows={rows}
            sx={classes.dataGrid}
          />
        </Box>
        <Box sx={classes.buttonsContainer}>
          <Button
            onClick={onCloseModal}
            sx={classes.leftButton}
            variant="outlined"
          >
            VOLVER
          </Button>
          {
            paymentData.status !== PaymentDisplayStatuses.cancelled && isAdmin && (
              <Button
                onClick={() => onClickPaymentModify(formatDataForUpdate())}
                sx={classes.rightButton}
                variant="contained"
              >
                ACEPTAR
              </Button>
            )
          }
        </Box>
      </Box>
    </Modal>
  );
};

export default memo(PaymentDetailsModal);
