import { InputDateRuleInterface, InputRuleInterface, PasswordRuleInterface } from '../interfaces/inputs';

const capitalizedMessage = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1);

const rulesConstants = {
  textfield: ({ fieldLabel, message }: InputRuleInterface) => ({
    required: {
      value: true,
      message: message || `${capitalizedMessage(fieldLabel.toLowerCase())} requerido`,
    },
  }),
  numericFieldValidation: ({ fieldLabel, message }: InputRuleInterface) => ({
    required: {
      value: true,
      message: message || `${capitalizedMessage(fieldLabel.toLowerCase())} requerido`,
    },
    pattern: {
      value: /^[1-9][0-9]*$/,
      message: `${capitalizedMessage(fieldLabel.toLowerCase())} debe ser un número mayor a 0`,
    },
  }),
  phoneNumberValidation: ({ fieldLabel, message }: InputRuleInterface) => ({
    required: {
      value: true,
      message: message || `${capitalizedMessage(fieldLabel.toLowerCase())} requerido`,
    },
    minLength: {
      value: 10,
      message: `${capitalizedMessage(fieldLabel.toLowerCase())} no válido`,
    },
  }),
  preinscriptionAmountValidate: () => ({
    validate: {
      required: (value: string, { isPreinscription }) => {
        if (isPreinscription) return Number(value) > 0;

        return true;
      },
    },
  }),
  discountFieldValidation: ({ fieldLabel, message }: InputRuleInterface) => ({
    required: {
      value: false,
      message: message || `${capitalizedMessage(fieldLabel.toLowerCase())} requerido`,
    },
    pattern: {
      value: /^(100|[0-9]{1,2})$/,
      message: `${capitalizedMessage(fieldLabel.toLowerCase())} debe ser un número mayor o igual a 0 y menor o igual a 100`,
    },
  }),
  datefield: ({ fieldLabel, message }: InputDateRuleInterface) => ({
    required: {
      value: true,
      message: message || `${capitalizedMessage(fieldLabel.toLowerCase())} requerido`,
    },
  }),
  videoTextfield: ({ fieldLabel, message }: InputRuleInterface) => ({
    required: {
      value: false,
      message: message || `${capitalizedMessage(fieldLabel.toLowerCase())} requerido`,
    },
    pattern: {
      value: /<iframe\b[^>]*>/i,
      message: 'Debe introducir un iframe válido.',
    },
  }),
  emailTextfield: ({ fieldLabel, message }: InputRuleInterface) => ({
    required: {
      value: true,
      message: message || `${capitalizedMessage(fieldLabel.toLowerCase())} requerido`,
    },
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\s*$/i,
      message: 'Debe introducir un email válido.',
    },
  }),
  select: ({ fieldLabel, message }: InputRuleInterface) => ({
    required: {
      value: true,
      message: message || `${capitalizedMessage(fieldLabel.toLowerCase())} requerido`,
    },
  }),
  passwordField: ({ message, password }: PasswordRuleInterface) => ({
    required: {
      value: true,
      message: message || 'Contraseña requerida',
    },
    validate: (value) => (password && value !== password ? 'Las contraseñas no coinciden' : null),
  }),
  fileUpload: () => ({
    required: {
      value: true,
      message: 'Archivo requerido',
    },
  }),
};

export default rulesConstants;
