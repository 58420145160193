import { createContext, useContext, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import usersService from '../../services/usersService';

import UserInterface, {
  CreateUserInterface,
  QueryGetUserByDocumentOrEmailInterface,
  QueryGetUsersInterface,
  ResGetCoursesFromUser,
  ResGetUserByDocumentOrEmailInterface,
  ResGetUsersInterface,
  UpdateUserInterface,
} from '../../interfaces/user';
import { ReactContainerProps } from '../../interfaces/props';
import { QueryGetEnrollmentsInterface } from '../../interfaces/enrollment';

const UsersContext = createContext(undefined);

export const useUsers = () => {
  const context = useContext(UsersContext);
  const { enqueueSnackbar } = useSnackbar();

  if (context === undefined) {
    throw new Error('useUsers must be used within a UsersProvider');
  }

  const fetchUserById = async (id: string, notifError: boolean = true): Promise<UserInterface> => {
    try {
      const user = await usersService.getById(id);
      return user;
    } catch (e) {
      if (notifError) {
        enqueueSnackbar(
          'Oops! Error inesperado al obtener el usuario',
          { variant: 'error' },
        );
      }
      return null;
    }
  };

  const fetchUsersByDocumentOrEmail = async (
    query?: QueryGetUserByDocumentOrEmailInterface,
  ): Promise<ResGetUserByDocumentOrEmailInterface> => {
    try {
      const userResponse = await usersService.getByDocumentOrEmail(query);
      return userResponse;
    } catch (e) {
      enqueueSnackbar(
        'Oops! Error inesperado al obtener los usuarios',
        { variant: 'error' },
      );
      return null;
    }
  };

  const verifySession = async () => {
    try {
      const { ok: isValidSession } = await usersService.checkAuthentication();

      return isValidSession;
    } catch (e) {
      return null;
    }
  };

  const createUser = async (user: CreateUserInterface): Promise<UserInterface> => {
    try {
      const userCreated = await usersService.create(user);
      return userCreated;
    } catch (e) {
      enqueueSnackbar(
        'El usuario ya existe, ingrese otro email o documento',
        { variant: 'error' },
      );
      return null;
    }
  };

  const fetchUsers = async (query?: QueryGetUsersInterface): Promise<ResGetUsersInterface> => {
    try {
      const users = await usersService.getAll(query);
      return users;
    } catch (e) {
      enqueueSnackbar(
        'Oops! Error inesperado al obtener los usuarios',
        { variant: 'error' },
      );
      return null;
    }
  };

  const removeUser = async (userId: string): Promise<UserInterface> => {
    try {
      return await usersService.remove(userId);
    } catch (e) {
      enqueueSnackbar(
        'Oops! Error inesperado al eliminar el usuario',
        { variant: 'error' },
      );
      return null;
    }
  };

  const updateUser = async (
    userId: string,
    userToUpdate: UpdateUserInterface,
  ): Promise<UserInterface> => {
    try {
      return await usersService.update(userId, userToUpdate);
    } catch (e) {
      enqueueSnackbar(
        typeof e === 'string' ? e : 'Oops! Error inesperado al actualizar el usuario',
        { variant: 'error' },
      );
      return null;
    }
  };

  const fetchCoursesByUserId = async (
    userId: string,
    query?: QueryGetEnrollmentsInterface,
  ): Promise<ResGetCoursesFromUser> => {
    try {
      const courses = await usersService.getCoursesByUserId(userId, query);
      return courses;
    } catch (e) {
      enqueueSnackbar('Oops! Error inesperado', { variant: 'error' });
      return null;
    }
  };

  return {
    createUser,
    fetchCoursesByUserId,
    fetchUserById,
    fetchUsers,
    fetchUsersByDocumentOrEmail,
    removeUser,
    updateUser,
    verifySession,
  };
};

const UsersProvider = ({ children }: ReactContainerProps) => {
  const value = useMemo(() => ({}), []);

  return (
    <UsersContext.Provider value={value}>
      {children}
    </UsersContext.Provider>
  );
};

export default UsersProvider;
