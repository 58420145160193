import { memo, useState } from 'react';
import { Box, Typography } from '@mui/material';

import { useLocation } from 'react-router-dom';
import LoadingComponent from '../../components/Controls/LoadingComponent';
import { formatPrice, formatPriceBasedOnInstallments } from '../../helpers/payments';
import SelectPaymentForm from './SelectPaymentForm';
import { useCourses } from '../../contexts/courses/context';

const classes = {
  selectPaymentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingX: { xs: '20px', sm: 0 },
    alignItems: { xs: 'center', sm: 'start' },
  },
  selectPaymentContainer: {
    maxWidth: { xs: '328px', sm: '100%' },
    width: '100%',
  },
};

interface Props {
  loading: boolean;
}

const SelectPayment = ({ loading }: Props) => {
  const {
    getAmount,
    isPreinscriptionPayment,
    getQuotes,
  } = useCourses();
  const { state } = useLocation();

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>(null);

  return (
    <Box sx={classes.selectPaymentWrapper}>
      <Box sx={classes.selectPaymentContainer}>
        <Box>
          <Typography variant="h2" fontWeight={700} color="primary.main">
            Selecciona un medio de pago
          </Typography>
          <Box my={1.25}>
            <LoadingComponent loadingSize={24} loading={loading}>
              <>
                <Typography variant="h3" display="inline" fontWeight={700}>
                  {`Valor ${isPreinscriptionPayment() ? 'de preinscripción:' : 'del curso:'} `}
                </Typography>
                <Typography variant="h3" display="inline" fontWeight={700}>
                  {formatPrice(getAmount())}
                </Typography>
              </>
            </LoadingComponent>
          </Box>
          {isPreinscriptionPayment()
            ? (
              <Typography variant="h4" fontWeight={500}>
                (No se descuenta del valor total del curso)
              </Typography>
            )
            : (
              <LoadingComponent loadingSize={24} loading={loading}>
                <Typography variant="h4" fontWeight={500}>
                  {`A pagar en 
                  ${getQuotes() === 1 ? `${getQuotes()} cuota` : `${getQuotes()} cuotas`} 
                  de ${formatPriceBasedOnInstallments(getAmount(), getQuotes())}`}
                </Typography>
              </LoadingComponent>
            )}
        </Box>

        <SelectPaymentForm
          value={selectedPaymentMethod}
          setValue={setSelectedPaymentMethod}
        />

        {state?.course.preinscription && (
        <>
          <Typography variant="h5" color="text.disabled">
            Precio total del curso:
          </Typography>
          {state?.course.tariff.map((tariff) => (
            <Typography variant="h5" color="text.disabled" key={tariff.category}>
              <span style={{ fontWeight: 700 }}>
                {`${tariff.category}: ${formatPrice(tariff.amount)} `}
              </span>
              (
              {`A pagar en ${tariff.quotes} ${tariff.quotes === 1 ? 'cuota' : 'cuotas'} de`}
              <span style={{ fontWeight: 700 }}>
                {` ${formatPriceBasedOnInstallments(tariff.amount, tariff.quotes)}`}
              </span>
              )
            </Typography>
          ))}
        </>
        )}
      </Box>
    </Box>
  );
};

export default memo(SelectPayment);
