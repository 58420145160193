import axios from 'axios';
import { axiosErrorHandler, axiosResponseHandler } from './handler';
import UserInterface, {
  CreateUserInterface,
  QueryGetUserByDocumentOrEmailInterface,
  QueryGetUsersInterface,
  ResGetCoursesFromUser,
  ResGetUserByDocumentOrEmailInterface,
  ResGetUsersInterface,
} from '../interfaces/user';
import { QueryGetEnrollmentsInterface } from '../interfaces/enrollment';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/users`,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});

const getAll = async (query?: QueryGetUsersInterface): Promise<ResGetUsersInterface> => instance
  .get('/', { params: query })
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const getCoursesByUserId = async (
  userId: string,
  query?: QueryGetEnrollmentsInterface,
): Promise<ResGetCoursesFromUser> => instance
  .get(`/${userId}/courses`, { params: query })
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const getById = async (userId: string): Promise<UserInterface> => instance
  .get(`/${userId}`)
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const getByDocumentOrEmail = async (
  query?: QueryGetUserByDocumentOrEmailInterface,
): Promise<ResGetUserByDocumentOrEmailInterface> => instance
  .get('/search', { params: query })
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const update = async (
  userId: string,
  user: Partial<UserInterface>,
)
: Promise<UserInterface> => instance
  .put(`/${userId}`, user)
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const create = async (user: CreateUserInterface): Promise<UserInterface> => instance
  .post('/', user)
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const remove = async (userId: string): Promise<UserInterface> => instance
  .delete(`/${userId}`)
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const checkAuthentication = async () => instance
  .get('/me')
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const uploadFile = async (formData: FormData): Promise<{ ok: boolean, message: string }> => instance
  .post('/upload-xlsx', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const getTemplate = async (): Promise<Blob> => instance
  .get('/bulk/template', { responseType: 'blob' })
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const restoreLastTemplate = async (): Promise<Blob> => instance
  .post('/restore/last/template')
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

export default {
  checkAuthentication,
  create,
  getAll,
  getByDocumentOrEmail,
  getById,
  getCoursesByUserId,
  remove,
  update,
  uploadFile,
  getTemplate,
  restoreLastTemplate,
};
