import { GridCellParams } from '@mui/x-data-grid';
import clsx from 'clsx';

import { formatPrice } from '../../helpers/payments';
import { buildColumn } from '../../helpers/tables';

import {
  PaymentDisplayDocumentTypes,
  PaymentDisplayMethods,
  PaymentDisplayStatuses,
  PopulatedPaymentInterface,
  RowInterface,
} from '../../interfaces/payments';
import { BuildPaymentColumn, CellClassName } from './types';

const buildRows = (
  rows: PopulatedPaymentInterface[],
): RowInterface[] => rows?.map(({
  _id,
  course,
  installmentsData,
  method,
  status,
  user,
}) => ({
  amount: formatPrice(String(installmentsData.reduce((acc, current) => acc + current.amount, 0))),
  courseName: course.name,
  document: `${PaymentDisplayDocumentTypes[user.documentType]} ${user.document}`,
  editCollection: null,
  email: user.email,
  fullName: `${user.first_name} ${user.last_name}`,
  id: _id,
  installmentsData,
  internalCourseId: course.internalCourseId,
  method: PaymentDisplayMethods[method],
  seeDetails: null,
  status: PaymentDisplayStatuses[status],
}));

const buildCellClassName = (params: GridCellParams<PopulatedPaymentInterface, string>) => {
  if (params.row.status === null) return '';

  return clsx('payment', {
    disabled: params.row.status === PaymentDisplayStatuses.cancelled,
  });
};

const buildPaymentColumn = ({
  field,
  flex,
  headerName,
  renderCellSlice,
  width,
}: BuildPaymentColumn) => buildColumn({
  cellAlign: true,
  cellClassName: (params: CellClassName) => buildCellClassName(params),
  field,
  flex,
  headerName,
  renderCellSlice,
  width,
});

const getColumns = (isAdmin = true) => [
  isAdmin && buildPaymentColumn({
    field: 'fullName', headerName: 'Nombre y Apellido', renderCellSlice: 32, width: 200,
  }),
  isAdmin && buildPaymentColumn({
    field: 'email', headerName: 'Email', renderCellSlice: 32, width: 200,
  }),
  isAdmin && buildPaymentColumn({
    field: 'document', headerName: 'Tipo y N° de Documento', width: 180,
  }),
  isAdmin && buildPaymentColumn({
    field: 'internalCourseId', headerName: 'ID Interno', width: 130,
  }),
  buildPaymentColumn({
    field: 'courseName', headerName: 'Nombre del curso', width: isAdmin ? 200 : 500, flex: isAdmin ? null : 1,
  }),
  buildPaymentColumn({
    field: 'amount', headerName: isAdmin ? 'Valor del arancel a pagar' : 'Valor total del curso', width: isAdmin ? 150 : 240,
  }),
  buildPaymentColumn({
    field: 'method', headerName: 'Medio de pago', width: 170,
  }),
  buildPaymentColumn({
    field: 'status', headerName: isAdmin ? 'Estado del cobro' : 'Estado del pago total', width: isAdmin ? 150 : 220,
  }),
].filter(Boolean);

export {
  buildRows,
  getColumns,
};
