import {
  ChangeEvent,
  FormEvent,
  memo,
  useState,
} from 'react';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useMessageModal } from '../../contexts/messageModal/context';
import paymentsService from '../../services/paymentsService';
import { CreatePaymentInterface, PaymentMethod } from '../../interfaces/payments';

const classes = {
  selectPaymentForm: {
    maxWidth: '328px',
    width: '100%',
  },
  textBold: {
    fontWeight: 'bold',
  },
  selectPaymentFormControl: {
    minHeight: '157px',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  selectPaymentFormRadioGroup: {
    boxSizing: 'border-box',
  },
  radio: {
    padding: '8px',
  },
  selectPaymentFormControlLabel: {
    marginX: 0,
  },
};

interface Props {
  value: string;
  setValue: (v: string) => void;
}

const SelectPaymentForm = ({ value, setValue }: Props) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { openMessageModal } = useMessageModal();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const hasCourseLoaded = state && Object.keys(state.course).length !== 0;
  const hasCourseAMercadoPagoTariff: boolean = hasCourseLoaded && state.course.payment_methods.find((payment_method) => payment_method === 'mercadopago');
  const hasCourseAPaymentLinkTariff: boolean = hasCourseLoaded && state.course.payment_methods.find((payment_method) => payment_method === 'paymentLink');

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setValue((event.target as HTMLInputElement).value);
    setError(false);
  };

  const handlePaymentLink = (): void => {
    setLoading(true);

    const payment: CreatePaymentInterface = {
      course: state.course.id,
      method: PaymentMethod.PaymentLink,
      conceptUser: state.conceptUser,
    };

    paymentsService.create(payment)
      .then(() => {
        setLoading(false);
        openMessageModal({
          title: 'VACANTE RESERVADA',
          message: 'Previo al inicio le enviaremos por mail el link con el que se suscribirá al pago mensual del curso.',
          variant: 'success',
          primaryButton: {
            text: 'Aceptar',
            onClick: () => navigate('/courses'),
          },
          closeButton: () => navigate('/courses'),
        });
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar(err, { variant: 'error' });
      });
  };

  const handleMercadoPagoPayment = (): void => {
    setLoading(true);

    const payment: CreatePaymentInterface = {
      course: state.course.id,
      method: PaymentMethod.MercadopagoSubscription,
      conceptUser: state.conceptUser,
    };

    paymentsService.create(payment)
      .then(({ redirect }: { redirect: string }) => {
        window.location.replace(redirect);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar(err, { variant: 'error' });
      });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (value === 'mercadopago' && hasCourseAMercadoPagoTariff) handleMercadoPagoPayment();
    else if (value === 'paymentLink' && hasCourseAPaymentLinkTariff) handlePaymentLink();
    else setError(true);
  };

  return (
    <Box
      component="form"
      mt={2.5}
      mb={1.5}
      sx={classes.selectPaymentForm}
      onSubmit={handleSubmit}
    >
      <FormControl
        error={error}
        variant="standard"
        sx={classes.selectPaymentFormControl}
      >
        <Box>
          <RadioGroup
            aria-labelledby="form-pagos"
            name="paymentMethods"
            value={value}
            onChange={handleRadioChange}
            sx={classes.selectPaymentFormRadioGroup}
          >
            {hasCourseAMercadoPagoTariff && (
              <FormControlLabel
                value="mercadopago"
                control={<Radio size="small" sx={classes.radio} />}
                sx={classes.selectPaymentFormControlLabel}
                label={(
                  <Typography variant="h5" display="inline">
                    Pago con
                    {' '}
                    <Typography component="span" sx={classes.textBold}>tarjeta de débito/crédito</Typography>
                    {' '}
                    o dinero en cuenta de MercadoPago
                  </Typography>
                )}
              />
            )}
            {hasCourseAPaymentLinkTariff && (
              <>
                <FormControlLabel
                  value="paymentLink"
                  control={<Radio size="small" sx={classes.radio} />}
                  sx={{
                    ...classes.selectPaymentFormControlLabel,
                    marginTop: 2,
                  }}
                  label={(
                    <Typography variant="h5" display="inline">
                      Pago por
                      {' '}
                      <Typography component="span" sx={classes.textBold}>link</Typography>
                    </Typography>
                  )}
                />
                <Box ml={4.5}>
                  <Typography variant="subtitle1" color="text.disabled">
                    Se le enviarán por mail las indicaciones
                  </Typography>
                </Box>
              </>
            )}
            {error && (<FormHelperText style={{ paddingLeft: '12px' }}>Debe seleccionar un medio de pago</FormHelperText>)}
          </RadioGroup>
        </Box>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          disabled={
            !hasCourseLoaded
            || (!hasCourseAMercadoPagoTariff && !hasCourseAPaymentLinkTariff)
          }
        >
          ADQUIRIR CURSO
        </Button>
      </FormControl>

      <Backdrop open={loading}>
        <CircularProgress size={48} />
      </Backdrop>
    </Box>
  );
};

export default memo(SelectPaymentForm);
