/* eslint-disable react/jsx-props-no-spreading */
import { Controller, ControllerRenderProps } from 'react-hook-form';
import {
  Autocomplete,
  Chip,
  FormControl,
  TextField,
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ControlledAutocompleteProps, OptionInterface } from 'src/interfaces/inputs';

const classes = {
  chip: {
    bgcolor: '#E2E5FA',
  },
};

const ControlledAutocomplete = ({
  control,
  disabled = false,
  fieldLabel,
  fieldName,
  multiple = false,
  options,
  placeholder = '',
  rules,
}: ControlledAutocompleteProps) => {
  const handleSelectChange = (
    field: ControllerRenderProps<any, string>,
    value,
  ) => {
    field.onChange(value);
  };

  const getValue = (field: ControllerRenderProps) => {
    if (multiple) return field.value;
    const value = options.find((option: OptionInterface) => field.value === option.id) || null;

    return value;
  };

  return (
    <Controller
      control={control}
      name={fieldName}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <FormControl
          error={!!error}
          fullWidth
          variant="standard"
        >
          <Autocomplete
            clearIcon={<CloseOutlinedIcon color="primary" />}
            defaultValue={field?.value || ''}
            disabled={disabled}
            getOptionLabel={(option) => (multiple ? option : option.name || '')}
            id={fieldName}
            isOptionEqualToValue={(option, value) => {
              if (value === '' || typeof value === 'undefined') return true;
              return multiple ? option === value : option?.id === value?.id;
            }}
            limitTags={multiple ? 2 : -1}
            multiple={multiple}
            onChange={(_event, value) => {
              const newValue = multiple ? value : value?.id || '';
              handleSelectChange(field, newValue);
            }}
            options={multiple ? options.map((op) => op.name) : options}
            onKeyDown={(event) => event.stopPropagation()}
            renderInput={(params) => (
              <TextField
                {...params}
                helperText={error && error.message}
                label={fieldLabel}
                placeholder={placeholder}
                variant="standard"
              />
            )}
            renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                // eslint-disable-next-line react/no-array-index-key
                key={`${option}-${index}`}
                label={option}
                size="small"
                sx={classes.chip}
              />
            ))}
            value={getValue(field)}
          />
        </FormControl>
      )}
    />
  );
};

export default ControlledAutocomplete;
