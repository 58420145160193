import { AxiosResponse, AxiosError } from 'axios';

const rejectPromiseWith = (message: string) => Promise.reject(message);

export const axiosResponseHandler = (response: AxiosResponse) => response.data;

export const axiosErrorHandler = (
  error: AxiosError<{ error: { message?: string } }>,
) => {
  if (error.response) {
    if (error.response.status === 401) {
      window.location.replace('/logout?sessionExpired');
    }

    if (error.response.status === 503 || error.response.status === 500) {
      return rejectPromiseWith('Oops! Un error ha ocurrido.');
    }
    return rejectPromiseWith(error.response.data.error?.message);
  }

  // Fallback
  return rejectPromiseWith('Oops! Un error ha ocurrido.');
};
