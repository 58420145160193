import { memo, useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  IconButton,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  DeleteOutline as DeleteOutlineIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';

import Tooltip from 'src/components/Tooltip';
import Layout from '../../components/Layouts/Layout';
import { useUsers } from '../../contexts/users/context';
import { useMessageModal } from '../../contexts/messageModal/context';

import TableSearchInput from '../../components/Inputs/TableSearchInput';
import FilterButton from '../../components/Buttons/FilterButton';

import { rolesDictionary } from '../../helpers/roles';
import { buildColumn } from '../../helpers/tables';

import UserInterface, { QueryGetUsersInterface, RowInterface } from '../../interfaces/user';

import { START_PAGE_SIZE } from '../../common/constants';

const classes = {
  pageTitle: { fontSize: '32px', fontWeight: 700, color: 'primary.main' },
  optionsContainer: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    justifyContent: 'center',
    alignItems: 'center',
    gap: 3,
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '65px',
    pt: 1,
  },
  contentContainer: {
    paddingY: 5,
    paddingX: 7,
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: 3, sm: 6 },
    height: 'auto',
    flexGrow: 1,
  },
  topContainer: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    gap: { xs: 3, md: 0 },
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inputOptions: {
    pl: 1,
    height: '34px',
    borderRadius: 0,
    '&:hover': {
    },
    'MuiOutlinedInput-notchedOutline': { border: '1px solid #909BD7' },
  },
};

const buildRows = (
  rows: UserInterface[],
): RowInterface[] => rows?.map(({
  document,
  documentType,
  email,
  first_name,
  id,
  isActive,
  last_name,
  phoneNumber,
  role,
}) => ({
  document: documentType.toUpperCase().concat(' ', document),
  email,
  id,
  isActive: isActive ? 'Sí' : 'No',
  name: first_name.concat(' ', last_name),
  phone: phoneNumber,
  role: rolesDictionary[role],
}));

const optionsFilters = [
  {
    label: rolesDictionary.admin,
    key: 'role',
    value: 'admin',
  },
  {
    label: rolesDictionary.teacher,
    key: 'role',
    value: 'teacher',
  },
  {
    label: rolesDictionary.student,
    key: 'role',
    value: 'student',
  },
];

const Users = () => {
  const navigate = useNavigate();
  const { fetchUsers, removeUser } = useUsers();
  const { openMessageModal } = useMessageModal();

  const [isFetchingUsers, setIsFetchingUsers] = useState<boolean>(false);
  const [rows, setRows] = useState<RowInterface[]>([]);
  const [hasMoreRows, setHasMoreRows] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [queryParam, setQueryParam] = useState<QueryGetUsersInterface>({
    limit: 10,
    page: START_PAGE_SIZE,
  });

  const setInitValues = (attributesToUpdate?: Partial<QueryGetUsersInterface>) => {
    setRows([]);
    setQueryParam((prevState) => ({
      ...prevState,
      ...attributesToUpdate,
      page: START_PAGE_SIZE,
    }));
  };

  const onClickRemoveUser = async (cell) => {
    openMessageModal({
      title: '¿Estás seguro/a que querés eliminar este usuario?',
      variant: 'infoError',
      message: 'Recordá que no podés deshacer esta acción, se eliminará todo el contenido',
      primaryButton: {
        text: 'Si, eliminar',
        onClick: async () => {
          await removeUser(cell.id);
          setInitValues();
        },
      },
      secondaryButton: {
        text: 'Volver',
      },
      centerText: true,
    });
  };

  const columns = [
    buildColumn({
      field: 'name',
      headerName: 'Nombre y Apellido',
      minWidth: 200,
      flex: 1,
      cellAlign: true,
      renderCellSlice: 32,
    }),
    buildColumn({
      field: 'role',
      headerName: 'Tipo de usuario',
      width: 180,
    }),
    buildColumn({
      field: 'email',
      headerName: 'Email',
      minWidth: 260,
      flex: 1,
      cellAlign: true,
      renderCellSlice: 32,
    }),
    buildColumn({
      field: 'document',
      headerName: 'Tipo y N° de Documento',
      width: 180,
    }),
    buildColumn({
      field: 'phone',
      headerName: 'Teléfono',
      width: 180,
    }),
    buildColumn({
      field: 'isActive',
      headerName: '¿Está activo?',
      width: 180,
    }),
    {
      field: 'options',
      headerName: '',
      width: 100,
      hideSortIcons: true,
      renderCell: (cell) => (
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <IconButton aria-label="edit" size="small" sx={{ width: '24px' }} onClick={() => navigate('./management', { state: { userId: cell.id } })}>
            <EditIcon color="primary" sx={{ fontSize: 16 }} />
          </IconButton>
          <IconButton
            aria-label="edit"
            size="small"
            sx={{ width: '24px' }}
            onClick={() => onClickRemoveUser(cell)}
          >
            <DeleteOutlineIcon color="primary" sx={{ fontSize: 16 }} />
          </IconButton>
        </Box>
      ),
    }];

  useEffect(() => {
    const getRows = async () => {
      setIsFetchingUsers(true);

      const usersFetched = await fetchUsers(queryParam);

      if (usersFetched) {
        const newRows = rows.length > 0
          ? [...rows, ...buildRows(usersFetched.users)]
          : buildRows(usersFetched.users);
        setRows(newRows);
        setHasMoreRows(newRows.length < usersFetched.total);
      }

      setIsFetchingUsers(false);
    };

    getRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParam]);

  const fetchNextUsers = async () => {
    setQueryParam((prevState) => ({ ...prevState, page: prevState.page + 1 }));
  };

  useEffect(() => {
    setInitValues({ search: search || undefined });
  }, [search]);

  return (
    <Layout marginContainer={false}>
      <Box sx={classes.contentContainer}>
        <Box sx={classes.topContainer}>
          <Typography variant="h1" sx={classes.pageTitle}>
            Usuarios
          </Typography>
          <Box sx={classes.optionsContainer}>
            <FormControl sx={{ width: '235px' }} variant="outlined">
              <TableSearchInput
                endAdornment={(
                  <Tooltip title="Buscar por nombre, apellido, DNI o email..." />
                )}
                label="Buscar"
                onSearch={(e) => setSearch(e)}
              />
            </FormControl>
            <FilterButton options={optionsFilters} query={queryParam} setQuery={setInitValues} />
          </Box>
        </Box>
        <Box height="100%" width="100%">
          <InfiniteScroll
            dataLength={rows.length}
            next={fetchNextUsers}
            hasMore={hasMoreRows}
            loader={<div />}
            style={{ maxHeight: '100%' }}
            scrollThreshold="100px"
          >
            <DataGrid
              rows={rows}
              columns={columns}
              sx={{ borderWidth: '0px' }}
              loading={isFetchingUsers}
              disableRowSelectionOnClick
              hideFooter
              disableColumnFilter
              disableColumnMenu
              autoHeight
            />
          </InfiniteScroll>
        </Box>
      </Box>
    </Layout>
  );
};

export default memo(Users);
